import { Injectable, computed, signal } from '@angular/core';
import { SetSettingDto, SettingDto, SettingService } from '@api-client';
import { SettingKey } from '@common';

@Injectable({ providedIn: 'root' })
export class SettingStore {
  settings = signal<SettingDto[]>([]);

  parsedSettings = computed(() => {
    const result: Record<string, string> = {};

    this.settings().forEach((setting) => {
      result[setting.key] = setting.value;
    });

    return result;
  });

  constructor(private readonly settingService: SettingService) {
    this.settingService.settingControllerGet().subscribe({
      next: (settings) => {
        this.settings.set(settings);
      },
    });
  }

  get(key: SettingKey) {
    return this.settings().find((setting) => setting.key === key)?.value;
  }

  update(settings: SetSettingDto[]) {
    return new Promise<void>((resolve) => {
      this.settingService
        .settingControllerUpdate({
          body: settings,
        })
        .subscribe({
          next: () => {
            this.settingService.settingControllerGet().subscribe({
              next: (settings) => {
                this.settings.set(settings);
                resolve();
              },
            });
          },
        });
    });
  }
}
